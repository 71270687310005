<template>
  <div>
    <Header />
    <div class="un-portfolio-cont">
      <div
        class="port-header pc"
        :style="{
          'background-image': 'url(' + this.srcImg(data.image) + ')',
          'background-size': '100%'
        }"
      >
        <div class="p-h-cont">
          <div class="cat-cont"  :class="{ 
                  bg_cat5 : data.category_name == 'MARKETING' ,
                  bg_cat2 : data.category_name == 'DEVELOPMENT' ,
                  bg_cat3 : data.category_name == 'DESIGN' ,
                  bg_cat6 : data.category_name == 'BRANDING' 
                  }" >{{data.category_name | split}}</div>
          <p class="titulo" v-bind:style="{ color: data.color }">{{data.title}}</p>
          <p class="descrp">
            {{data.descrp}}
          </p>
        </div>
      </div>

      <div
        class="port-header mo"
        :style="{
          'background-image': 'url(' + this.srcImg(data.imagemo) + ')',
          'background-size': '100%'
        }"
      >
        <div class="p-h-cont">
          <div class="cat-cont"  :class="{ 
                  bg_cat5 : data.category_name == 'MARKETING' ,
                  bg_cat2 : data.category_name == 'DEVELOPMENT' ,
                  bg_cat3 : data.category_name == 'DESIGN' ,
                  bg_cat6 : data.category_name == 'BRANDING' 
                  }" >{{data.category_name | split}}</div>
          <p class="titulo" :class="{'changesize' : islong}" v-bind:style="{ color: data.color }">{{changeSize(data.title)}}</p>
          <p class="descrp">
            {{data.descrp}}
          </p>
        </div>
      </div>

        <!-- cuerpo del proyecto -->
                 <div v-for="(bloq, key) in data.body" :key="key" class="bloq-item "> 

                     <div v-if="bloq.tipo !='paleta'"  class="dflx mtpblq">
                        <div v-if="bloq.tipo=='reto'" class="text-item bloq-reto">
                                  <p class="cat-item">El Reto</p>
                                  <p class="titulo-item">
                                    {{bloq.reto.texto}}
                                  </p>
                                  <p class="text-item" v-html="bloq.reto.parrafo">
                                  </p>
                           </div>

                         <p class="texto-bloq" v-if="bloq.tipo =='texto'" v-html="bloq.texto" ></p>

                        <div v-if="bloq.tipo =='video'" v-html="bloq.video" class="video-bloq"></div>
                       
                        <img v-if="bloq.tipo =='imagen'" :src="srcImg(bloq.imagen)"  alt="imagen"
                        class="imagen-larga principal-mo"
                        width="1609"
                        height="717">

                        <div v-if="bloq.tipo =='caja'"  class="two-items">
                              <div> <!--CAJA 1 -->
                                  <div v-if="bloq.caja[0].tipo=='reto'" class="text-item">
                                  <p class="cat-item">El Reto</p>
                                  <p class="titulo-item">
                                    {{bloq.caja[0].reto.texto}}
                                  </p>
                                  <p class="text-item" v-html="bloq.caja[0].reto.parrafo">
                                  </p>
                                </div>
                                <img v-if="bloq.caja[0].tipo=='imagen'"
                                  :src="srcImg(bloq.caja[0].imagen)"
                                  alt="chocolate"
                                  class="image-item"
                                />

                                 <p class="texto-bloq-caja" v-if="bloq.caja[0].tipo =='texto'" v-html="bloq.caja[0].texto" ></p>

                                  <p v-if="bloq.caja[0].tipo=='titulo_centrado'" class="titulo-centrado-caja">
                                    {{bloq.caja[0].titulo_centrado}}
                                  </p>
                                  <p v-if="bloq.caja[0].tipo=='subtitulo_centrado'" class="subtitulo-centrado-caja">
                                    {{bloq.caja[0].subtitulo_centrado}}
                                  </p>


                              </div>
                              <div> <!--CAJA 2 -->
                                  <div v-if="bloq.caja[1].tipo=='reto'" class="text-item">
                                  <p class="cat-item">El Reto</p>
                                  <p class="titulo-item">
                                    {{bloq.caja[1].reto.texto}}
                                  </p>
                                  <p class="text-item" v-html="bloq.caja[1].reto.parrafo">
                                  </p>
                                </div>
                                <img v-if="bloq.caja[1].tipo=='imagen'"
                                  :src="srcImg(bloq.caja[1].imagen)"
                                  alt="chocolate"
                                  class="image-item"
                                />

                                 <p class="texto-bloq-caja" v-if="bloq.caja[1].tipo =='texto'" v-html="bloq.caja[1].texto" ></p>

                                 
                                  <p v-if="bloq.caja[1].tipo=='titulo_centrado'" class="titulo-centrado-caja">
                                    {{bloq.caja[1].titulo_centrado}}
                                  </p>
                                  <p v-if="bloq.caja[1].tipo=='subtitulo_centrado'" class="subtitulo-centrado-caja">
                                    {{bloq.caja[1].subtitulo_centrado}}
                                  </p>



                              </div>

                        </div>


                        <div>
                           <div  >
                            <p v-if="bloq.tipo=='titulo_centrado'" class="titulo-centrado">
                              {{bloq.titulo_centrado}}
                            </p>
                            <p v-if="bloq.tipo=='subtitulo_centrado'" class="subtitulo-centrado">
                              {{bloq.subtitulo_centrado}}
                            </p>
                          </div>
                        </div>

                         <div v-if="bloq.tipo =='galeria'" class="three-photos-cont">
                        
                             <div class="image-cont img-height" v-for="(img,i) in bloq.galeria" :key="i">
                            <a > <!-- :href="thimg.link" -->
                              <div :style="{'background-image':'url('+  urlpath.url() + '/project-img/' + img+')'}"
                              class="image-frame pc" />
                              <img :src="urlpath.url() + '/project-img/' + img" alt="image" class="image-c mo">
                            </a>
                          </div>
                           
                        </div>

                        <div v-if="bloq.tipo =='slider'" class="three-photos-cont">

                          <div class="pc-item">
                            <div class="image-cont flex" v-for="(img,i) in bloq.slider" :key="i">
                              <a > <!--:href="thimg.link" -->
                                <div :style="{'background-image':'url('+urlpath.url() + '/project-img/' + img+')'}"
                                class="image-frame" />
                              </a>
                            </div>              
                          </div>


                          <div class="mobile-item">
                            <agile :options="options">
                              <div class="image-cont flex" v-for="(img,i) in bloq.slider" :key="i">
                                <a > <!-- :href="thimg.link" -->
                                  <div :style="{'background-image':'url('+urlpath.url() + '/project-img/' + img+')'}"
                                  class="image-frame" />
                                </a>
                              </div>
                          <template slot="prevButton">
                            <div class="divPrev0">
                              <img class="PButton" src="../assets/Frame_Prev.svg">
                            </div>
                          </template>
                          <template slot="nextButton">
                            <div class="divNext0">
                              <img class="NButton" src="../assets/Frame_Next.svg">
                            </div>
                          </template>
                            </agile>
                          </div>
                        </div>



                     
                     
                     
                     </div>

                     <div v-else class="color-palette-cont mtpblq"> <!-- bloque de tipo paleta -->
                      <p class="color-c-t">Color palette</p>
                          <div class="imagen-palette" >
                              <img v-if="bloq.tipo =='paleta'" :src="srcImg(bloq.imagen)"  alt="imagen">
                          </div>
                            
                     </div>

                
                      

                 </div>



     


     


    </div>

    <div class="mtpblq">
       <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/on-site/Header";
import Footer from "../components/on-site/Footer.vue";
import { VueAgile } from "vue-agile";
let urlpath = require('../global/url')
import {mapActions} from 'vuex';

export default {
  name: "Services",
  data() {
    return {
          urlpath:urlpath,
      colores: [
        {
          nombrecolor: "Pink",
          color: "#F6AED9",
        },
        {
          nombrecolor: "Pink",
          color: "#F8D9A8",
        },
        {
          nombrecolor: "Pink",
          color: "#E6412E",
        },
        {
          nombrecolor: "Pink",
          color: "#E9C8FF",
        },
      ],
      threeimages: [
        {
          titulo: "imagen1",
          link: "/",
          image: require("../assets/porkilo-5.svg"),
        },
        {
          titulo: "imagen2",
          link: "/",
          image: require("../assets/porkilo-6.png"),
        },
        {
          titulo: "imagen3",
          link: "/",
          image: require("../assets/porkilo-7.png"),
        },
      ],
      threeimagestwo: [
        {
          titulo: "imagen1",
          link: "/",
          image: require("../assets/porkilo-8.png"),
        },
        {
          titulo: "imagen2",
          link: "/",
          image: require("../assets/porkilo-9.png"),
        },
        {
          titulo: "imagen3",
          link: "/",
          image: require("../assets/porkilo-10.png"),
        },
      ],
      options: {
        infinite: true,
        slidesToShow: 1,
        navButtons: true,
        dots: false,
      },
      islong: false,
    };
  },
  components: {
    Header,
    Footer,
    agile: VueAgile,
  },
  async created (){
        let payload={
            id:this.$route.params.url,
            option: "project-url",
        }
        
         await this.getInfoByIdPrj(payload);
  },
  computed:{
   data() {
             let array =this.$store.getters["project/data"];
           
            return array;
        },
  },
  methods: {
        ...mapActions("project", ["getInfoByIdPrj"]),
    getOption: function (option) {
      this.liOpc = option;
    },
    changeSize(word){
      let w = word.split(' ')
      if(w[0].length > 5){
        this.islong = true
      }
      else if(w[1].length > 5){
        this.islong = true
      }
      return word;
    },
      srcImg:function (img){
      let src;
                 src = `${urlpath.url()}/project-img/${img}`
                return src        

        },
       
        dayFormat(fecha){
            return dayjs(fecha).format('DD/MM/YYYY')
        },
         dayFormat2(fecha){
              dayjs.locale("es");
            return dayjs(fecha).format('DD/MMMM/YYYY')
        }
  },
  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      if (len == 1) {
        return splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);
      }

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let titleM = this.$route.params.url
                let title = titleM.replaceAll('-',' ').replaceAll('/','_')
                document.title = to.meta.title || title+' - Legrafica';
            }
        },
  }
};
</script>

<style scoped>
.pc{
  display: flex!important;
}
.mo{
  display: none!important;
}
/** nuevos estilos */
p.texto-bloq{
  width: 80vw ;
   font-family: IBM Plex Mono;
    margin-left: 9vw;
    font-size: 0.8333333333333334VW;
}

p.texto-bloq-caja{
      width: 36vw;
    font-family: IBM Plex Mono;
    
    font-size: 0.8333333333333334VW;
}

  .imagen-palette img{
    width: 100%;
  }
  .mtpblq{
    margin-top: 6.666666666666667VW;
  }

  p.titulo-centrado{
    font-size: 1.3020833333333335VW;
    width: 37.604166666666664VW;
    margin-left: 30.9375VW;
    text-align: center;
     font-family: Gramatika-Bold;
     margin-top: 0vw;
     margin-bottom: 0vw;
  }

   p.subtitulo-centrado{
    font-size: 0.8333333333333334VW;
    width: 33.90625VW;
    margin-left: 33.645833333333336VW;
    text-align: center;
    font-family: IBM Plex Mono;
     margin-top: 0vw;
     margin-bottom: 0vw;
  }

   p.titulo-centrado-caja{
    font-size: 1.3020833333333335VW;
    width: 37.604166666666664VW;
  
    text-align: center;
     font-family: Gramatika-Bold;
     margin-top: 0vw;
     margin-bottom: 0vw;
  }

   p.subtitulo-centrado-caja{
    font-size: 0.8333333333333334VW;
    width: 33.90625VW;
   
    text-align: center;
    font-family: IBM Plex Mono;
     margin-top: 0vw;
     margin-bottom: 0vw;
  }

  .two-items .image-item{
        width: 42.542vw;
    /*height: 40.542vw;*/
  }
/** */

.port-header {
  width: 100%;
  height: 57.292vw;
  background-color: #acfddd;
  background-size: 76.094vw;
  background-repeat: no-repeat;
  background-position-y: bottom;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.p-h-cont {
  width: 31.74vw;
  padding-right: 13.656vw;
}
.cat-cont {
  font-family: Gramatika-Bold;
  font-size: 0.625vw;
  line-height: 135.8%;
  color: #ffffff;
  width: 5.938vw;
  text-align: center;
      padding-top: 0.2vw;
    padding-bottom: 0.4vw;
  background-color: #3702fb;
}
p.titulo {
  font-family: Gramatika-Medium;
  font-size: 6.771vw;
  line-height: 121.3%;
  color: #000000;
  margin: 0;
}
.descrp {
  width: 21.458vw;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 0.729vw;
  line-height: 149.5%;
  color: #231f20;
  margin: 0;
  padding-top: 2.031vw;
}
.imagen-larga {
  width: 83.802vw;
  height: auto;
  margin: auto;
}
.first-sec {
  width: 100%;
  padding: 6.667vw 0;
  display: flex;
  flex-direction: column;
}

.bloq-reto{
  width: 83.802vw;
   margin: 3.708vw auto;
}

.two-items p.cat-item, .bloq-reto p.cat-item {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 1.042vw;
  line-height: 125%;
  color: #ef67c2;
  margin: 0;
  padding-bottom: 4.167vw;
}
.two-items p.titulo-item ,  .bloq-reto p.titulo-item {
  margin: 0;
  font-family: Gramatika-Bold;
  font-size: 1.823vw;
  line-height: 125%;
  color: #000000;
  padding-bottom: 4.167vw;
}
.two-items p.text-item {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 0.833vw;
  line-height: 190.5%;
  color: #231f20;
  margin: 0;
  width: 32.969vw;
}

.bloq-reto p.text-item{
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 0.833vw;
  line-height: 190.5%;
  color: #231f20;
  margin: 0;
}

.color-palette-cont .c-p-cont {
  display: flex;
  width: 100%;
}
.color-palette-cont .color-item {
  width: inherit;
  height: 44.896vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.color-palette-cont .color-c-t {
  font-family: Gramatika-Bold;
  font-size: 1.823vw;
  line-height: 125%;
  color: #000000;
  text-align: center;
  margin: 0;
  padding-bottom: 6.146vw;
}
.color-palette-cont p.color-t,
.color-palette-cont p.color-st {
  margin: 0;
}
.color-palette-cont p.color-t {
  font-family: Gramatika-Bold;
  font-size: 2.344vw;
  line-height: 125%;
  color: #ffffff;
}
.color-palette-cont p.color-st {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 0.938vw;
  line-height: 125%;
  text-align: center;
  color: #ffffff;
}
.three-photos-cont {
    width: 88.021vw;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.image-cont:nth-child(1) {
    width: 100%;
    height: 44.740vw;
}
.image-cont:nth-child(2),.image-cont:nth-child(3) {
    width: 50%;
    height: 38.74vw;
}
.image-frame {
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position-y: top;
}
.image-cont.flex{
  width: 33%;
  height: 45.417vw;
}
.two-items.section .text-item {
    width: 51.198vw;
    padding-bottom: 11.406vw;
}
.two-items.section {
    flex-direction: column;
}
.two-items.section p.text-item{
    width: 35.198vw;
    margin: auto;
}
.two-items.section p.titulo-item,.two-items.section p.text-item {
    text-align: center;
}
.pc-item{
    display: contents;
}
.mobile-item{
  display: none;
}
@media (max-width: 768px) {
.pc{
  display: none!important;
}
.mo{
  display: flex!important;
}
.port-header {
    width: 100%;
    height: 158.454vw;
    background-size: 110%;
    background-position-x: 0vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}.first-sec {
    padding: 13.768vw 0;
}.p-h-cont {
    width: 70.633vw;
    padding-right: 0;
    padding-top: 33.092vw;
}.cat-cont {
    font-size: 1.932vw;
    width: 21.014vw;
    padding: 0.8vw 0;
}p.titulo {
    font-size: 19.324vw;
}.descrp {
    width: 66.458vw;
    font-size: 2.174vw;
    padding-top: 2.031vw;
}.imagen-larga.principal-mo {
    width: 100%;
    height: 73.43vw;
    margin: auto;
    object-fit: cover;
}.imagen-larga {
    width: 84.541vw;
    height: auto;
    margin: auto;
}.two-items {
    width: 84.541vw;
    margin: 12.077vw auto;
    flex-direction: column;
}.two-items .text-item {
    width: 100%;
}.two-items p.cat-item {
    font-size: 2.657vw;
    padding-bottom: 8.937vw;
}.two-items p.titulo-item {
    font-size: 5.314vw;
    line-height: 125%;
    padding-bottom: 8.167vw;
}.two-items p.text-item {
    font-size: 2.174vw;
    width: 100%;
}.two-items .image-item {
    width: 100%;
}.color-palette-cont .color-c-t {
    font-size: 5.314vw;
    padding-bottom: 14.493vw;
}.color-palette-cont .c-p-cont {
    display: flex;
    width: 100%;
    flex-direction: column;
}.color-palette-cont p.color-t {
    font-size: 8.454vw;
    line-height: 125%;
}.color-palette-cont p.color-st {
    font-size: 4.348vw;
}.two-items.section .text-item {
    width: 100%;
}.two-items.section p.text-item {
    width: 64.734vw;
}.image-cont.flex {
    height: 110.628vw;
}
.pc-item{
  display: none;
}
.mobile-item{
    display: contents;
}.image-frame {
    width: 69vw;
    height: 100%;
    background-size: 100%;
    background-position-y: top;
    margin: auto;
}
.divPrev0, .divNext0 {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987EF3;
    border-radius: 30vw;
    width: 9.662vw;
    height:9.662vw;
    margin-top: -59vw;
    margin-left: 2.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divPrev0 img, .divNext0 img{
    width: 1.2vw;
}.divNext0 {
    margin-right: 0;
}
.mtpblq {
    margin-top: 12.077vw;
}
p.titulo-centrado {
    font-size: 5.314vw;
    width: 70.773vw;
    margin-left: 12.319vw;
    text-align: left;
}
p.subtitulo-centrado,p.texto-bloq{
    width: 70.773vw;
    font-weight: 400;
    font-size: 2.657vw;
    line-height: 190.5%;
    text-align: left;
    margin-left: 12.319vw;
}
.three-photos-cont {
    flex-direction: column;
}
.image-cont:nth-child(1),.image-cont:nth-child(2),.image-cont:nth-child(3) {
    width: 100%;
}
.img-height{
    height: auto!important;
}
.image-c {
    width: 100%!important;
    height: auto;
    margin: 0;
}
.changesize{
  font-size: 9.724vw!important;
}
}
</style>